import React, { useCallback, useContext, useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorContext } from "../ErrorContext";
import { AuthContext, Authorization } from "../AuthContext";
import { Card } from "react-bootstrap";
import { ViispMockAuth } from "./ViispMockAuth";
import { createSessionViisp, toAuthorizationViisp } from "../../resources/auth";
import ContentBase from "../Layout/Partials/Content/ContentBase";
import { ViispAuthenticationStart } from "./ViispAuthenticationStart";

export const Login: React.FC = () => {
    const { setError, setErrorMessage } = useContext(ErrorContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const setAuthorizationCallback = useCallback((authorization: Authorization) => {  
        auth.updateAuth(authorization);
        navigate("/", {replace:true});
    }, []);

    useEffect(() => {
        const status =  searchParams.get("viispStatus") === null ? "" : searchParams.get("viispStatus");
        if(status && status.indexOf("MOCK-") > 0){
            const ticket = status?.slice(status.indexOf("MOCK-"));            
            createSessionViisp(ticket).then(async (values) => {    
                if(Array.isArray(values) &&  values.at(0).code === 'common.error.viisp_org_not_allow'){
                    navigate("/", {replace:true});
                    setErrorMessage({messageKey:"Šiam vartotojui prisijungimas negalimas.", debugInfo:""});
                    
                } else {
                    localStorage.setItem("session",JSON.stringify(values));
                    const authorization: Authorization = await toAuthorizationViisp(values);                
                    setAuthorizationCallback(authorization);
                }
            })
            .catch(setError);          
        } else if(status){
            const ticket = status?.slice(status.indexOf("ticket=") + 7);         
            createSessionViisp(ticket).then(async (values) => {  
                if(Array.isArray(values) &&  values.at(0).code === 'common.error.viisp_org_not_allow'){
                    navigate("/", {replace:true});
                    setErrorMessage({messageKey:"Šiam vartotojui prisijungimas negalimas.", debugInfo:""});
                    
                } else if(Array.isArray(values) &&  values.at(0).code === 'common.error.viisp_user_not_allow'){
                    navigate("/", {replace:true});
                    setErrorMessage({messageKey:"Šiam vartotojui prisijungimas negalimas.", debugInfo:""});
                    
                } else {
                    localStorage.setItem("session",JSON.stringify(values));
                    const authorization: Authorization = await toAuthorizationViisp(values);                
                    setAuthorizationCallback(authorization);
                } 
            })

        }
    }, [auth, setError]);

    return (
        <ContentBase title="Prisijunkite">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            {/* {"VIISP MOCK"} */}
                            <ViispAuthenticationStart />
                            {/* {process.env.REACT_APP_TEST && <ViispMockAuth/>} */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </ContentBase>
    );
};

export default Login;
